/* approvalLayout.css */
.approvalLayout {
    display: flex;
  }
  
  .approval-menu {
    background-color: #f8f9fa;
    width: 250px;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .approval-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .approval-menu li {
    padding: 16px;
    border-bottom: 1px solid #e3e6f0;
  }
  
  .approval-menu li:last-child {
    border-bottom: none;
  }
  
  .approval-menu a {
    text-decoration: none;
    color: #5a5c69;
    font-size: 16px;
    display: block;
  }
  
  .approval-menu a.active {
    font-weight: bold;
    color: #4e73df;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 24px;
  }
  