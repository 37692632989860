.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.7rem 0.5rem;
  border-top: 1px solid #e2e2e2;
}

.toggle {
  display: flex;
  align-items: center;
  position: relative;
  width: 90px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow: hidden;
}

.toggle-circle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
}

.toggle-on-text,
.toggle-off-text {
  position: absolute;
  top: 0;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
  transition: opacity 0.3s ease;
}
.toggle-on-text {
  opacity: 1;
  left: 0;
}

.toggle-off-text {
  opacity: 0;
  right: 0;
}

.toggle.on {
  background-color: #28a745;
}

.toggle.on .toggle-circle {
  transform: translateX(58px);
}

.toggle.on .toggle-on-text {
  opacity: 1;
}

.toggle.on .toggle-off-text {
  opacity: 0;
}

.toggle.off {
  background-color: #6c757d;
}

.toggle.off .toggle-circle {
  transform: translateX(2px);
}

.toggle.off .toggle-on-text {
  opacity: 0;
}

.toggle.off .toggle-off-text {
  opacity: 1;
}

.toggle-container label {
  font-size: 0.875rem;
  color: #4d4d4d;
}

.toggle-container input {
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
}
