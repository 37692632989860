.submitButton {
  width: 100%;
  border: none;
  line-height: 3em;
  height: 50px;
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButton button {
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  width: 100%;
  height: 50px;
  display: block;
  /* alternative */
  
}

.showCursor {
  cursor: pointer;
}

.bgDisable {
  background: gray;
}

.bgEnable {
  background: #c1d82f;
}