.tabs-wrapper {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #004aad;
    background-color: #f8f8f8;
  }
  
  .tab {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    color: #6a6a6a;
    cursor: pointer;
    font-size: 16px;
    margin: 0 20px;
    padding: 15px 0;
    transition: color 0.3s, border-bottom 0.3s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .tab:hover {
    color: #004aad;
    border-bottom: 2px solid #004aad;
  }
  
  .tab.active {
    color: white;
    background-color: rgb(1, 149, 211);
    padding: 0.5rem;
    border-radius: 8px 8px 0 0;
  }
  
  .tab-content {
    padding: 30px;
    text-align: center;
  }
  