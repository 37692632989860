.error {
    width: 100%;
    background-color: red;
    padding: 0.5rem 3em;
    border: 1px solid #e2e2e2;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error h1 {
    font-size: .875rem;
}