
.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 30px 30px 0;
  margin-bottom: 1rem;
}

.form-group label {
  width: 150px;
  margin-right: 10px;
}

.form-group select,
.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 200px;
  font-size: 16px;
}

.form-group button {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background-color: #0078d4;
  color: #fff;
  cursor: pointer;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.dropdown-header {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  overflow: auto;
  max-height: 200px;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item label {
  display: block;
  margin-bottom: 5px;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 10px;
}

.dropdown-item input[type="checkbox"]:checked + span {
  font-weight: bold;
}

.fa {
  margin-left: 5px;
}

.fa-angle-up {
  transform: rotate(180deg);
}
