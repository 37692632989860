.disabled {
  pointer-events: none;
}

.employee-search-container .blue {
  color: #0195d3;
  text-decoration: none;
}

.employee-search-container {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 0.5rem;
}

.employee-search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  background-color: #0195d3;
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.employee-search-header-content {
  display: flex;
  align-items: center;
}

.employee-selection-clear-button {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  color: #0195d3;
  border: 1px solid #0195d3;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 3px;
}

.employee-selection-clear-button:hover {
  background-color: #0195d3;
  color: #ffffff;
}

.employee-search-header-icon {
  margin-left: 10px;
}

.employee-search-input-container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

.employee-search-input {
  flex-grow: 1;
  padding: 5px;
  border: none;
  border-bottom: 2px solid #0195d3;
  background-color: #f5f5f5;
  font-size: 16px;
  outline: none;
}

.employee-search-input-icon {
  margin-left: 10px;
  color: #0195d3;
}

.employee-search-clear-button {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: #0195d3;
  border: 1px solid #0195d3;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 3px;
}

.employee-search-clear-button:hover {
  background-color: #0195d3;
  color: #ffffff;
}

.employee-search-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-search-list-item {
  border-bottom: 1px solid #e0e0e0;
}

.employee-search-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 0.875rem;
  cursor: pointer;
}

.employee-search-subordinates-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-search-subordinates-list-item {
  padding: 10px 40px;
  font-size: 0.875rem;
  background-color: #f5f5f5;
  cursor: pointer;
}

.employee-search-subordinates-list-item:hover {
  background-color: #e0e0e0;
}

.employee-details {
  padding: 10px 20px;
  width: 100%;
  position: relative;
}

.employee-details.multiple:hover {
  background: #f5f5f5;
}
.employee-details.multiple {
  border-bottom: 1px solid #e0e0e0;
}

.employee-details-clear-button {
  display: inline-block;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: #0195d3;
  border: 1px solid #0195d3;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 3px;
}

.employee-details-clear-button:hover {
  background-color: #0195d3;
  color: #ffffff;
}

.details-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}
.details-row div {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.selected-employees-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
}

.selected-employee-remove-btn {
  margin-left: 0.5rem;
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.selected-employee-remove-btn:hover {
  background-color: #cc0000;
}
