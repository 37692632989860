.landingPage {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 305px));
  justify-content: center;
  max-width: 1024px;
  margin: 1rem auto;
  padding: 1rem;
}
.landingPage section span {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid rgb(138, 138, 138);
  color: rgb(138, 138, 138);
  max-width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
}
.landingPage section a {
  text-decoration: none;
}
