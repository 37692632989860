.requestHistory h3 {
  text-align: center;
}
.requestHistory .filters {
  display: flex;
  gap: 1rem;
}

.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}

.requestHistory .esfInput {
  width: 50%;
  border: none;
}
.requestHistory select {
  flex-grow: 1;
  margin-left: 0.5rem;
}
.requestHistory .requests ul {
  list-style-type: none;
  padding: 0;
}
.requestHistory .request {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding: 0.5rem;
  width: 100%;
  color: rgb(130, 123, 112);
}
.requestHistory .request .requestInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.requestHistory .request .requestInfo div {
  display: flex;
  gap: 0.5rem;
}
.requestHistory .request .closeButton {
  padding: 0.5rem;
  color: red;
  border-radius: 50%;
  border: 1px solid red;
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.7rem;
}
.requestHistory .bold {
  font-weight: bold;
  color: rgb(34, 34, 34);
}
.requestHistory .request svg {
  color: black;
  margin-right: 0.2rem;
}
