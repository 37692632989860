.tile section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 1rem;
    color: white;
    border-radius: 6px;
}
.tile {
    text-decoration: none;
}
.tile h1 {
    font-size: 1.1rem;
}
.tile img {
    max-width: 25%;
}