.dataRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  min-height: 45px;
  font-size: 0.875rem;
  padding: 0.7rem 0.5rem;
  width: 100%;
  color: #4d4d4d;
}
.dataRow .blue {
  color: #3f51b5;
  font-weight: 500;
}
.dataRow .idAndLocation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.dataRow .idAndLocation span{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}