.note {
    width: 100%;
    background-color: #ffb612;
    line-height: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 0 24px;
}
.note p {
    font-size: .8rem;
}
.note .red {
    color: #a61e26;
    font-weight: bold;
}