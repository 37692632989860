.title {
    width: 100%;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}

.title h1 {
    font-size: 16px;
}