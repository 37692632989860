.esfInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 45px;
  padding: 0.7rem 0.5rem;
  border-top: 1px solid #e2e2e2;
  font-size: 0.875rem;
}

.esfInput select {
  /* border: none;
  outline: none; */
  padding: 8px 32px 8px 8px;
  border: 1px solid #7f8c8d;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
  width: 43%;
  min-width: 230px;
}

.esfInput .labelInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.esfInput label,
.esfInput select,
.esfInput textarea,
.esfInput input,
.esfInput p {
  color: #4d4d4d;
}

.esfInput .required {
  color: red;
  font-weight: 700;
  font-size: 0.8rem;
}

.esfInput p {
  font-size: 0.8rem;
}

.esfInput input {
  /* background: #eee;
  text-align: right;
  color: #4d4d4d;
  margin: 0;
  border: none;
  box-shadow: none;
  padding: 0;
  outline: none;
  height: 1.5em; */
  width: 43%;
  padding: 8px 32px 8px 8px;
  border: 1px solid #7f8c8d;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
  min-width: 230px;
}

.esfInput textarea {
  width: 100%;
  /* border: none;
  outline: none;
  height: 50px;
  font-size: 0.875rem; */
  padding: 8px 32px 8px 8px;
  border: 1px solid #7f8c8d;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

.esfInput textarea:-moz-placeholder,
/* Firefox 18- */
.esfInput textarea::-moz-placeholder,
/* Firefox 19+ */
.esfInput textarea:-ms-input-placeholder,
/* IE 10+ */
.esfInput textarea::-webkit-input-placeholder,
/* Webkit based */
.esfInput textarea::placeholder {
  /* Modern browsers */
  color: #4d4d4d;
}