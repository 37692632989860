.dropdown {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  right: 0;
  top: 54px;
  align-items: center;
}

.dropdown a:hover {
  background-color: #f5f5f5;
}

.dropdown a {
  color: black;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
}
.dropdown span {
  padding: 0.5rem;
}
.menu-icon {
  cursor: pointer;
}
