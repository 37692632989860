body * {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  box-sizing: border-box;
}

.loader {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.approvalQueueControls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}

.exportButton {
  text-align: right;
  margin: 1rem;
  background-color: rgb(141, 198, 63);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  padding: .5rem;
}

.loginLogOut {
  text-align: center;
  margin: 1rem;
  background-color: rgb(141, 198, 63);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  padding: .5rem;
  width: 85px;
}

.exportButton:hover {
  background-color: rgba(141, 198, 63, 0.7)
}

.ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
  background-color: rgb(1, 149, 211);
  color: white;
}

.ag-header-cell.ag-header-cell-sortable.ag-focus-managed:hover {
  background-color: rgb(1, 149, 211) !important;
  color: white;
}

.clearFilters {
  text-align: right;
  margin: 1rem;
  background-color: rgb(63, 175, 198);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  padding: .5rem;
}

.buttonContainer {
  display: flex;
}