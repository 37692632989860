.logo {
  max-width: 170px;
}
.logoContainer {
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;
  font-size: .8rem;
}
