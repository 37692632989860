header {
  padding: 0.5rem 0;
  border-bottom: 1px solid #c7c7c7;
  box-shadow: 0 1px 5px 0 rgb(50 50 50 / 10%);
  background: #fbfbfb;
  transition: height 2s;
  width: 100%;
  display: grid;
  grid-template-columns: 35px 90% 35px;
  justify-content: center;
  align-items: center;
}
.homeIcon {
  max-width: 2rem;
}
